// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_container {

    width: 100%;

    text-align: center;

    font-size: 14px;

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.table_head {

    border-bottom-width: 1px;

    text-align: left;

    font-size: 14px;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(0 4 14 / var(--tw-text-opacity))
}

.table_head > tr> th,.table_head > th, .table_head_data {

    white-space: nowrap;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 0.75rem;

    padding-bottom: 0.75rem;

    font-size: 14px
}
.table_row {

    min-height: 80px;

    cursor: pointer;

    border-bottom-width: 1px;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    text-align: left
}
.table_row:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(246 246 247 / var(--tw-bg-opacity))
}

.table_row> td,.table_data {

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 1rem;

    padding-bottom: 1rem;

    text-align: left
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/styles.css"],"names":[],"mappings":"AACI;;IAAA,WAAiD;;IAAjD,kBAAiD;;IAAjD,eAAiD;;IAAjD,oBAAiD;;IAAjD;AAAiD;;AAIjD;;IAAA,wBAAsE;;IAAtE,gBAAsE;;IAAtE,eAAsE;;IAAtE,gBAAsE;;IAAtE,oBAAsE;;IAAtE;AAAsE;;AAItE;;IAAA,mBAA8C;;IAA9C,qBAA8C;;IAA9C,sBAA8C;;IAA9C,oBAA8C;;IAA9C,uBAA8C;;IAA9C;AAA8C;AAG9C;;IAAA,gBAA6E;;IAA7E,eAA6E;;IAA7E,wBAA6E;;IAA7E,kBAA6E;;IAA7E,yDAA6E;;IAA7E;AAA6E;AAA7E;;IAAA,kBAA6E;;IAA7E;AAA6E;;AAI7E;;IAAA,qBAAyB;;IAAzB,sBAAyB;;IAAzB,iBAAyB;;IAAzB,oBAAyB;;IAAzB;AAAyB","sourcesContent":[".table_container {\n    @apply w-full text-[14px] text-center text-black ;\n}\n\n.table_head {\n    @apply text-[14px] border-b-[1px] text-left text-[#00040E] font-normal;\n}\n\n.table_head > tr> th,.table_head > th, .table_head_data{\n    @apply px-3 py-3 text-[14px] whitespace-nowrap;\n}\n.table_row {\n    @apply bg-white border-b text-left cursor-pointer hover:bg-tGray min-h-[80px];\n}\n\n.table_row> td,.table_data {\n    @apply px-3 py-4 text-left\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
