// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewPhotoModalContainer {
    background-color: rgba(5, 5, 5, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.viewPhoto_ModalContainer > .boxContainer {
    background-color: rgba(46, 46, 46, 0.7);
    position: relative;
    display: flex;
    height: 95%;
    width: 90%;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
}

.modal_overlay_container {
    background-color: rgba(5, 5, 5, 0.1);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
    height: 100vh;
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/styles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kCAA0B;YAA1B,0BAA0B;IAC1B,eAAoF;IAApF,SAAoF;IAApF,QAAoF;IAApF,YAAoF;IAApF,aAAoF;IAApF,aAAoF;IAApF,YAAoF;IAApF,mBAAoF;IAApF,uBAAoF;AACxF;;AAEA;IACI,uCAAuC;IACvC,kBAA6E;IAA7E,aAA6E;IAA7E,WAA6E;IAA7E,UAA6E;IAA7E,mBAA6E;IAA7E,uBAA6E;IAA7E,uBAA6E;AACjF;;AAEA;IACI,oCAAoC;IACpC,kCAA0B;YAA1B,0BAA0B;IAC1B,eAAoD;IAApD,SAAoD;IAApD,QAAoD;IAApD,YAAoD;IAApD,aAAoD;IAApD,YAAoD;AACxD","sourcesContent":[".viewPhotoModalContainer {\n    background-color: rgba(5, 5, 5, 0.1);\n    backdrop-filter: blur(5px);\n    @apply flex items-center justify-center z-[999] fixed left-0 top-0 h-screen w-screen;\n}\n\n.viewPhoto_ModalContainer > .boxContainer {\n    background-color: rgba(46, 46, 46, 0.7);\n    @apply relative flex items-center   justify-center h-[95%] w-[90%] rounded-md;\n}\n\n.modal_overlay_container {\n    background-color: rgba(5, 5, 5, 0.1);\n    backdrop-filter: blur(5px);\n    @apply fixed left-0 top-0 h-screen w-screen z-[999] ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
