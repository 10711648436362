import React from "react";

import { SubscriptionPackageInterface } from "interfaces";
import { getCurrency } from "helpers";
import { MyButton } from "components";
import Skeleton from "react-loading-skeleton";

function PackageCard({
  packageDetails,
  isLoading,
  subscribeHandler,
}: {
  isLoading?: boolean;
  packageDetails?: SubscriptionPackageInterface;
  subscribeHandler?(id: string);
}) {
  return (
    <div
      className={
        "flex flex-col gap-5 bg-gray-100 p-5 rounded-md text-gray-500 justify-between"
      }
    >
      <div className={"flex flex-col gap-3"}>
        {isLoading ? (
          <>
            <Skeleton />
            <Skeleton width={150} />
          </>
        ) : (
          <>
            <span className={"font-bold text-[22px] text-black"}>
              {packageDetails?.name}
            </span>
            <span className={"truncate "}>{packageDetails?.description}</span>
          </>
        )}
      </div>
      <div className={"flex flex-col gap-1"}>
        {isLoading ? (
          <>
            <Skeleton width={50} />
            <Skeleton width={80} />
          </>
        ) : (
          <>
            {/*<span className={"font-bold text-[22px] text-black"}>*/}
            {/*  {getCurrency()}{packageDetails?.price}*/}
            {/*</span>*/}
            {/*<span>*/}
            {/*  for {packageDetails?.validDuration?.durationQuantity}*/}
            {/*  {packageDetails?.validDuration?.durationUnit}*/}
            {/*</span>*/}
          </>
        )}
      </div>
      <div className={"flex flex-1"}>
        {!isLoading && (
          <MyButton
            name={"Start Plan"}
            onClick={() => subscribeHandler(packageDetails?._id)}
          />
        )}
      </div>
    </div>
  );
}

export default PackageCard;
