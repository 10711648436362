import { useContext, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import { createPortal } from "react-dom";

import { BoxModalInterface } from "./interfaces";
import { AppContext } from "context";

export default function ModalComponent({
  title,
  closeHandler,
  children,
  isCloseAuto,
  containerStyle,
}: BoxModalInterface) {
  const { state, handlers } = useContext(AppContext);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeHandler();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, []);

  useEffect(() => {
    if (state.isActionSuccess && isCloseAuto) {
      closeHandler();
      handlers.setSuccess(false);
    }
  }, [state.isActionSuccess]);

  return createPortal(
    <div className="fixed flex items-start pt-20 justify-center h-screen w-screen left-0 top-0 z-[999] bg-gray-700 bg-opacity-70">
      <div className={`bg-white min-w-[40vw] rounded-md p-5 ${containerStyle}`}>
        <div className="flex justify-between items-center border-b-2 pb-2">
          <span className={"font-bold text-[16px] text-gray-900"}>{title}</span>
          <IoIosClose
            fontSize={25}
            cursor="pointer"
            onClick={() => closeHandler()}
            color="gray"
          />
        </div>
        <div className="p-2 max-h-[80vh] overflow-y-scroll">{children}</div>
      </div>
    </div>,
    document.getElementById("popup-root")
  );
}
