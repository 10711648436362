// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layoutIndexContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.childrenContainer_layout {
  display: grid;
  grid-template-columns: auto 1fr;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;AACpB","sourcesContent":[".layoutIndexContainer {\n  display: grid;\n  grid-template-rows: auto 1fr;\n  height: 100vh;\n}\n\n.childrenContainer_layout {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
