import { TextIconInterface } from "./interfaces";

export default function TextIcon({
  Icon,
  text,
  textStyle,
  iconColor,
}: TextIconInterface) {
  return (
    <div className="flex gap-1 items-center">
      <div>
        <Icon fontSize="16" color={iconColor} />
      </div>
      <span
        className={`${textStyle} text-md pl-1 text-ellipsis overflow-hidden whitespace-nowrap`}
      >
        {" "}
        {text}
      </span>
    </div>
  );
}
