// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templateContent {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: scroll;
  margin: 1.25rem;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/templates/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;EAC5B,kBAAkB;EAClB,eAAkC;EAAlC,uBAAkC;EAAlC,kBAAkC;EAAlC,yDAAkC;EAAlC,gBAAkC;AACpC","sourcesContent":[".templateContent {\n  display: grid;\n  grid-template-rows: auto 1fr;\n  overflow-y: scroll;\n  @apply bg-white m-5 p-5 rounded-md;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
