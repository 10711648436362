const config = {
  development: {
    // default: process.env.REACT_APP_API_HOST_URL,
    default: "https://api.rentalbux.com/api/system/",
  },

  production: {
    // default: process.env.REACT_APP_API_HOST_URL,
     default: "https://api.rentalbux.com/api/system/",
  },
};

export default process.env.NODE_ENV === "production"
  ? config["production"]
  : config["development"];
