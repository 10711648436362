// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_container{
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: left;
}

.input_label{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(0 4 14 / var(--tw-text-opacity));
}

.input_textError{
  letter-spacing: 1px;
  --tw-text-opacity: 1;
  color: rgb(200 16 46 / var(--tw-text-opacity));
}


.passwordInput{
  outline: none !important;
}

.input_style, input[type='number'],input[type='text'], input[type='date'],input[type='time'],input[type="datetime-local"] {
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  justify-content: center;
  --tw-text-opacity: 1;
  color: rgb(0 4 14 / var(--tw-text-opacity));
  --tw-ring-color: transparent;
}

/* hide the count in number*/
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea:focus{
  border-width: 1px;
  border-color: #2680EB;
  border-style: solid;
}

.custom-react-select__input-container > [type="text"]:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-transition: background-color 600000s 0s, color 600000s 0s;
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

input[type='date']{
  padding-right: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/myInputs/styles.css"],"names":[],"mappings":"AACE;EAAA,aAA2C;EAA3C,sBAA2C;EAA3C,SAA2C;EAA3C;AAA2C;;AAI3C;EAAA,aAA+D;EAA/D,mBAA+D;EAA/D,eAA+D;EAA/D,gBAA+D;EAA/D,oBAA+D;EAA/D;AAA+D;;AAI/D;EAAA,mBAAgC;EAAhC,oBAAgC;EAAhC;AAAgC;;;AAIlC;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,aAA0E;EAA1E,eAA0E;EAA1E,uBAA0E;EAA1E,oBAA0E;EAA1E,2CAA0E;EAA1E,4BAA0E;AAC5E;;AAEA,4BAA4B;AAC5B;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;AACrB;;AAGE;EAAA,8BAAmC;EAAnC,mBAAmC;EAAnC;AAAmC;;AAGrC;;EAEE,iEAAyD;EAAzD,yDAAyD;AAC3D;AACA;EACE,wCAAwC;AAC1C;;AAGE;EAAA;AAAW","sourcesContent":[".input_container {\n  @apply flex flex-col  gap-[14px] text-left ;\n}\n\n.input_label {\n  @apply flex items-center text-[14px] font-medium text-[#00040E];\n}\n\n.input_textError {\n  @apply text-tRed tracking-normal;\n}\n\n\n.passwordInput{\n  outline: none !important;\n}\n\n.input_style, input[type='number'],input[type='text'], input[type='date'],input[type='time'],input[type=\"datetime-local\"] {\n  -webkit-appearance: none;\n  @apply ring-transparent text-[#00040E] cursor-pointer  flex justify-center;\n}\n\n/* hide the count in number*/\ninput[type=number]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n}\n\ntextarea:focus{\n  border-width: 1px;\n  border-color: #2680EB;\n  border-style: solid;\n}\n\n.custom-react-select__input-container > [type=\"text\"]:focus{\n  @apply ring-transparent outline-none\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:focus {\n  transition: background-color 600000s 0s, color 600000s 0s;\n}\ninput[data-autocompleted] {\n  background-color: transparent !important;\n}\n\ninput[type='date'] {\n  @apply pr-5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
