import AuthRoutes from "./authModule";
import PackageRoutes from "./packageModule";
import RolesPermissionsRoutes from "./rolesPermissions";
import UserRoutes from "./usersModule";
import CustomerRoutes from "./customerModule";
import SettingRoutes from "./settingsModule";
import DashboardRoutes from "./dashboardModule";
import ProfileRoutes from "./profileModule";
import PurchaseHistoryRoutes from "./purchaseHistoryModule";

export {
  AuthRoutes,
  PackageRoutes,
  RolesPermissionsRoutes,
  UserRoutes,
  CustomerRoutes,
  SettingRoutes,
  DashboardRoutes,
  ProfileRoutes,
  PurchaseHistoryRoutes,
};
