import MyPasswordInput from "./MyPasswordInput";
import MyTextField from "./MyTextField";
import MySearchInput from "./MySearchInput";
import MyTextAreaInput from "./MyTextAreaInput";
import MyUploadPhoto from "./MyUploadPhoto";
import MySelectField from "./MySelectField";
import MyRadioInput from "./MyRadioInput";
import MyUploadDocument from "./MyUploadDoucment";
import MySearchSelect from "./MySearchSelect";

export {
  MyTextField,
  MyPasswordInput,
  MySearchInput,
  MyTextAreaInput,
  MyUploadPhoto,
  MySelectField,
  MyRadioInput,
  MyUploadDocument,
  MySearchSelect,
};
