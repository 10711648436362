import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { AddressTextInterface } from "./interfaces";

function AddressText({ address, textStyle, style }: AddressTextInterface) {
  let country =
    typeof address?.county == "string"
      ? address?.county
      : address?.county?.name;

  return (
    <div
      style={style}
      className={`flex flex-col items-center text-[14px] ` + textStyle}
    >
      <span>{[address?.address1, address?.address2].toString()}</span>
      <div className={"flex gap-1 items-center justify-start"}>
        <IoLocationOutline />
        <span>{[country, address?.postalCode].toString()}</span>
      </div>
    </div>
  );
}

export default AddressText;
