import {NavLink} from 'react-router-dom';
import Popup from 'reactjs-popup';

const NavPillComponent = ({ linkData, isExpand }) => {
  let Icon = linkData.icon;

  return (
    <NavLink
      className={({ isActive }) => {
        return `flex flex-col  px-4 py-1 text-gray-500 hover:border-r-4 hover:border-primary select-none hover:text-gray-800 mb-4 ${
          isActive && 'border-r-4 border-primary text-gray-800 '
        } ${isExpand ? 'min-w-[220px]' : 'min-w-[60px]'}`;
      }}
      key={linkData.name}
      to={linkData.path}>
      <Popup
        key={`tp-`}
        trigger={
          <button type='button' className='button'>
            <div className='flex items-center justify-start gap-4 ml-2'>
              <Icon fontSize={20} />
              {isExpand && <span>{linkData.name}</span>}
            </div>
          </button>
        }
        position={'right bottom'}
        on={['hover']}
        contentStyle={{ backgroundColor: '#242B2E', width: 100 }}
        arrow
        arrowStyle={{ color: '#242B2E' }}>
        <div className='text-center text-white'>
          <span className='text-[14px]'>{linkData.name}</span>
        </div>
      </Popup>
    </NavLink>
  );
};

export default NavPillComponent;
