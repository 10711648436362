// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting_navLink {
    display: flex;
    height: 45px;
    align-items: center;
    gap: 0.5rem;
    border-bottom-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
    font-size: 14px;
    --tw-text-opacity: 1;
    color: rgb(1 33 105 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/layout/assets/style.css"],"names":[],"mappings":"AACI;IAAA,aAA4F;IAA5F,YAA4F;IAA5F,mBAA4F;IAA5F,WAA4F;IAA5F,wBAA4F;IAA5F,sBAA4F;IAA5F,yDAA4F;IAA5F,eAA4F;IAA5F,oBAA4F;IAA5F;AAA4F","sourcesContent":[".setting_navLink{\n    @apply flex items-center gap-2 h-[45px] text-[14px]  border-b-2 border-gray-100 text-primary;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
