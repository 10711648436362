export enum DocumentTypeEnum {
  CERTIFICATE = "Certificate",
}

export interface RentalDocumentInterface {
  _id: string;
  property: string;
  unit: string;
  documentOf: "Property" | "Unit";
  documentType: string;
  hasTenantAccess: boolean;
  isActive: boolean;
  isDeleted: boolean;
  description: string;
  filePath: string;
}
