import TextIcon from "./TextIcon";
import AddressText from "./AddressText";
import NameValueText from "./NameValueText";
import LeftRightHeader from "./LeftRightHeader";
import BackNavigationText from "./BackNavigationText";
import UserTableProfile from "./UserTableProfile";
import StatusText from "./StatusText";

export {
  TextIcon,
  AddressText,
  StatusText,
  NameValueText,
  LeftRightHeader,
  BackNavigationText,
  UserTableProfile,
};
