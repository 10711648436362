import Skeleton from "react-loading-skeleton";
import { createPortal } from "react-dom";

import { useToggleHooks } from "useHooks";
import { MdClose, MdSaveAlt } from "react-icons/md";

import { downloadLink, getImageUrlHandler } from "helpers";
import { ViewPhotoModalInterface } from "./interfaces";
import "./styles.css";

export default function ViewPhotosModal({
  imagePath,
  imageStyle,
  isLoading,
  tailwindStyle,
  needLink,
}: ViewPhotoModalInterface) {
  const { isToggle, setToggleHandler } = useToggleHooks();
  const downloadImageHandler = () => {
    downloadLink(imagePath, "hyreImage");
  };
  return (
    <>
      <div>
        {isLoading ? (
          <Skeleton
            className={tailwindStyle}
            style={{ ...imageStyle, width: "100%", height: "150px" }}
          />
        ) : (
          <img
            style={imageStyle}
            onClick={setToggleHandler}
            className={`${tailwindStyle} object-contain rounded-md w-screen cursor-pointer `}
            src={needLink ? getImageUrlHandler(imagePath) : imagePath}
            alt="viewImage"
          />
        )}
      </div>
      {isToggle &&
        createPortal(
          <div className="viewPhotoModalContainer">
            <div className="absolute top-10 right-10 flex items-center gap-5 ">
              <MdSaveAlt
                cursor="pointer"
                size={30}
                onClick={downloadImageHandler}
                title="Save"
              />
              <MdClose
                onClick={setToggleHandler}
                title="close"
                size={40}
                color="white"
                cursor="pointer"
              />
            </div>

            <img
              src={needLink ? getImageUrlHandler(imagePath) : imagePath}
              alt="more"
              className="bg-white object-contain w-[45%] h-[60%] rounded-md"
            />
          </div>,
          document.getElementById("popup-root")
        )}
    </>
  );
}
