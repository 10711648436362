import { MyToggleButtonInterface } from "./interfaces";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { MdToggleOff, MdToggleOn } from "react-icons/md";

export default function MyToggleButton({
  changeHandler,
  value,
  options,
  type = "text",
  buttonType,
}: MyToggleButtonInterface) {
  let isTypeText = type === "text";
  let currentText;
  if (isTypeText) {
    currentText = value ? options?.active : options?.inActive;
  }

  const onClickHandler = () => {
    changeHandler(!value);
  };
  const activeButton =
    buttonType == "toggle" ? (
      <MdToggleOn className={" text-tGreen  text-[35px]"} />
    ) : (
      <AiFillCheckCircle className={" text-tGreen  text-[20px]"} />
    );
  const inActiveButton =
    buttonType == "toggle" ? (
      <MdToggleOff className={" text-gray-300  text-[35px] "} />
    ) : (
      <AiOutlineCheckCircle className={"text-gray-500  text-[20px]"} />
    );

  return (
    <div className={"flex items-center justify-center cursor-pointer"}>
      {isTypeText ? (
        <div
          className={`rounded-full py-1 px-4 text-[12px]  cursor-pointer text-white ${currentText?.className} `}
          onClick={onClickHandler}
        >
          {currentText?.name}
        </div>
      ) : (
        <div onClick={onClickHandler}>
          {value ? activeButton : inActiveButton}
        </div>
      )}
    </div>
  );
}
