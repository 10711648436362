// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideNavComponent {
  grid-template-rows: auto 1fr;
  display: grid;
  -webkit-user-select: none;
          user-select: none;
  gap: 0.25rem;
  overflow: scroll;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
}

.sidenav_LinkContainer {
  position: relative;
  display: flex;
  height: 110px;
  width: 110px;
  cursor: pointer;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.375rem;
  font-size: 12px;
}
.sidenav_activeLink {
  border-right-width: 5px;
  --tw-border-opacity: 1;
  border-color: rgb(1 33 105 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.sideNav_activeBadge {
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
  height: 7px;
  width: 7px;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(200 16 46 / var(--tw-bg-opacity));
}

.sideNav_childLinkContainer {
  position: relative;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-bottom-width: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1rem;
  font-size: 14px;
}

.topNav_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  border-bottom-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 640px) {

  .topNav_container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


.topNav_setting_modal {
  display: flex;
  min-height: 150px;
  cursor: pointer;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.topNav_userDetails {
  display: flex;
  gap: 1rem;
  border-bottom-width: 1.5px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

`, "",{"version":3,"sources":["webpack://./src/components/layout/components/style.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAuD;EAAvD,yBAAuD;UAAvD,iBAAuD;EAAvD,YAAuD;EAAvD,gBAAuD;EAAvD,qBAAuD;EAAvD,oBAAuD;AACzD;;AAGE;EAAA,kBAA6F;EAA7F,aAA6F;EAA7F,aAA6F;EAA7F,YAA6F;EAA7F,eAA6F;EAA7F,sBAA6F;EAA7F,YAA6F;EAA7F,uBAA6F;EAA7F;AAA6F;AAG7F;EAAA,uBAA2D;EAA3D,sBAA2D;EAA3D,sDAA2D;EAA3D,kBAA2D;EAA3D,yDAA2D;EAA3D,oBAA2D;EAA3D;AAA2D;AAG3D;EAAA,kBAAkE;EAAlE,WAAkE;EAAlE,cAAkE;EAAlE,WAAkE;EAAlE,UAAkE;EAAlE,qBAAkE;EAAlE,kBAAkE;EAAlE;AAAkE;;AAIlE;EAAA,kBAA2G;EAA3G,aAA2G;EAA3G,YAA2G;EAA3G,mBAA2G;EAA3G,2BAA2G;EAA3G,SAA2G;EAA3G,wBAA2G;EAA3G,kBAA2G;EAA3G,yDAA2G;EAA3G,kBAA2G;EAA3G;AAA2G;;AAI3G;EAAA,aAAqF;EAArF,mBAAqF;EAArF,8BAAqF;EAArF,YAAqF;EAArF,wBAAqF;EAArF,kBAAqF;EAArF,mBAAqF;EAArF,iBAAqF;EAArF;AAAqF;;AAArF;;EAAA;IAAA,kBAAqF;IAArF;EAAqF;AAAA;;;AAKrF;EAAA,aAAwE;EAAxE,iBAAwE;EAAxE,eAAwE;EAAxE,sBAAwE;EAAxE,WAAwE;EAAxE,uBAAwE;EAAxE,oBAAwE;EAAxE;AAAwE;;AAIxE;EAAA,aAA+C;EAA/C,SAA+C;EAA/C,0BAA+C;EAA/C,sBAA+C;EAA/C;AAA+C","sourcesContent":[".sideNavComponent {\n  grid-template-rows: auto 1fr;\n  @apply grid overflow-scroll gap-1 select-none pl-5 pt-5;\n}\n\n.sidenav_LinkContainer {\n  @apply flex flex-col relative gap-3 w-[110px] h-[110px] rounded-md text-[12px] cursor-pointer;\n}\n.sidenav_activeLink {\n  @apply text-black border-r-[5px] border-primary bg-gray-100;\n}\n.sideNav_activeBadge{\n  @apply rounded-full bg-tRed w-[7px] h-[7px] absolute top-2 right-1;\n}\n\n.sideNav_childLinkContainer {\n  @apply flex items-center justify-start pl-4 gap-5 bg-white h-[50px]   relative gap-4 border-b-2 text-[14px];\n}\n\n.topNav_container {\n  @apply flex justify-between items-center gap-5 sm:px-4 px-[25px] py-[10px] border-b-2;\n}\n\n\n.topNav_setting_modal {\n  @apply flex flex-col gap-2 cursor-pointer rounded-md px-2\t min-h-[150px];\n}\n\n.topNav_userDetails {\n  @apply flex gap-4 border-b-[1.5px] border-black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
