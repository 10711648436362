import {useEffect, useState} from 'react';

export default function usePositionHooks() {
  const [positionData, setAddressData] = useState({
    department: [],
    designation: {},
  });
  const decodeToOptions = (data) => {
    return data?.map((e) => {
      return {
        value: e?._id || e,
        name: e?.name || e,
      };
    });
  };

  const getDepartmentHandler = async () => {
    try {
      if (positionData.department.length > 1) return;
      // const res = await Api.get(
      //   ApiUrl.utils.nepalLocality.get_province,
      //   null,
      //   'hyreAdmin'
      // );
      let department = decodeToOptions([
        {
          _id: 'sales-1',
          name: 'Sales Department',
        },
        {
          _id: 'it-1',
          name: 'IT Department',
        },
      ]);
      setAddressData((e) => {
        return {
          ...e,
          department: department,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDesignationHandler = async (departmentId) => {
    try {
      if (!departmentId) return;
      if (positionData.designation[departmentId]) return;

      // const res = await Api.get(
      //   ApiUrl.utils.nepalLocality.get_municipal + districtId,
      //   null,
      //   'hyreAdmin'
      // );

      let tempDesignation = { ...positionData.designation };
      tempDesignation[departmentId] = decodeToOptions([
        {
          _id: 'Ma-45',
          name: 'Manager',
        },
      ]);
      setAddressData((e) => {
        return {
          ...e,
          designation: tempDesignation,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartmentHandler();
  }, []);

  return {
    getDesignationHandler,
    getDepartmentHandler,
    positionData,
  };
}
