import { getIconHelper } from "helpers";
import "./styles.css";
import { IconTypeEnum } from "interfaces";

export default function Loader() {
  const Logo = getIconHelper(IconTypeEnum.LOGO);

  return (
    <div className="fixed z-[10000] flex items-center justify-center bg-gray-700 bg-opacity-5 w-full h-full top-0 left-0">
      <div className="flex gap-0 flex-col items-center ">
        {/* <img
          src="/img/logo/logo.svg"
          alt="logo"
          className="w-[200px] h-[60px] object-contain"
        /> */}
        <div className="w-[200px] h-[60px] object-contain">
          <Logo />
        </div>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
