import { IconTypeEnum } from "interfaces";
import {
  IoIosAdd,
  IoIosNotificationsOutline,
  IoIosClose,
  IoMdJournal,
} from "react-icons/io";
import { IoReloadCircleOutline } from "react-icons/io5";
import { GiHouseKeys } from "react-icons/gi";
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineCheck,
  AiOutlineSearch,
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineMail,
  AiFillCheckCircle,
} from "react-icons/ai";
import {
  BsBuilding,
  BsImages,
  BsLayers,
  BsQuestionCircle,
  BsSquare,
  BsSquareFill,
  BsToggleOff,
  BsToggleOn,
  BsDot,
  BsCheckAll,
  BsDownload,
  BsFileEarmarkText,
} from "react-icons/bs";
import {
  BiChevronLeft,
  BiChevronRight,
  BiTrash,
  BiStats,
  BiRadioCircleMarked,
  BiRadioCircle,
  BiMinus,
} from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import {
  MdOutlineBed,
  MdOutlineDeleteSweep,
  MdOutlineTimer,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import { VscTag } from "react-icons/vsc";
import { CiDiscount1 } from "react-icons/ci";
import { ReactComponent as BookingConfirm } from "images/icons/booking_confirm.svg";
import { ReactComponent as BookingPending } from "images/icons/booking_pending.svg";
import { ReactComponent as BookingCancelled } from "images/icons/booking_cancelled.svg";
import { ReactComponent as Home } from "images/icons/home.svg";
import { ReactComponent as PropertyValue } from "images/icons/property_value.svg";
import { ReactComponent as RentalIncomeTotal } from "images/icons/rental_income_total.svg";
import { ReactComponent as RentalProfile } from "images/icons/rental_profit.svg";
import { ReactComponent as SalesStatusDraft } from "images/icons/sales_status_draft.svg";
import { ReactComponent as SalesSettled } from "images/icons/sales_settled.svg";
import { ReactComponent as UnitOccupied } from "images/icons/unit_occupied.svg";
import { ReactComponent as UnitVacant } from "images/icons/unit_vacant.svg";
import { ReactComponent as DashboardHandHome } from "images/icons/dashboard_PropertyValue.svg";
import { ReactComponent as SettingCircleIcon } from "images/icons/setting_circle.svg";
import { ReactComponent as CalendarIcon } from "images/icons/calendarIcon.svg";
import { ReactComponent as DollarIcon } from "images/icons/dollorIcon.svg";
import { ReactComponent as GrowthIcon } from "images/icons/growthIcon.svg";
import { ReactComponent as HomeHandIcon } from "images/icons/homeHandIcon.svg";
import { ReactComponent as UserIcon } from "images/icons/userIcon.svg";
import { ReactComponent as UsersIcon } from "images/icons/usersIcon.svg";
import { ReactComponent as Logo } from "images/icons/logo.svg";

const getIconHelper = (iconType: IconTypeEnum) => {
  switch (iconType) {
    case IconTypeEnum.LAYER:
      return BsLayers;
    case IconTypeEnum.LOGO:
      return Logo;
    case IconTypeEnum.FILE:
      return BsFileEarmarkText;
    case IconTypeEnum.CALENDAR:
      return CalendarIcon;
    case IconTypeEnum.GROWTH:
      return GrowthIcon;
    case IconTypeEnum.HOME_HAND:
      return HomeHandIcon;
    case IconTypeEnum.USERS:
      return UsersIcon;
    case IconTypeEnum.USER:
      return UserIcon;
    case IconTypeEnum.DOLLAR:
      return DollarIcon;
    case IconTypeEnum.MINUS:
      return BiMinus;
    case IconTypeEnum.CHECK_FILL:
      return AiFillCheckCircle;
    case IconTypeEnum.UN_SELECTED_RADIO:
      return BiRadioCircleMarked;
    case IconTypeEnum.SELECTED_RADIO:
      return BiRadioCircle;
    case IconTypeEnum.MAIL:
      return AiOutlineMail;
    case IconTypeEnum.CHECK_ALL:
      return BsCheckAll;
    case IconTypeEnum.DOWNLOAD:
      return BsDownload;
    case IconTypeEnum.DOT:
      return BsDot;
    case IconTypeEnum.DASHBOARD_HAND_HOME:
      return DashboardHandHome;
    case IconTypeEnum.DASHBOARD_SETTING:
      return SettingCircleIcon;
    case IconTypeEnum.BOOKING_CANCELLED:
      return BookingCancelled;
    case IconTypeEnum.DOWN_ARROW:
      return AiOutlineArrowDown;
    case IconTypeEnum.UP_ARROW:
      return AiOutlineArrowUp;
    case IconTypeEnum.RELOAD:
      return IoReloadCircleOutline;
    case IconTypeEnum.BOOKING_CONFIRM:
      return BookingConfirm;
    case IconTypeEnum.BOOKING_PENDING:
      return BookingPending;
    case IconTypeEnum.SALES_SETTLED:
      return SalesSettled;
    case IconTypeEnum.SALES_STATUS_DRAFT:
      return SalesStatusDraft;
    case IconTypeEnum.UNIT_OCCUPIED:
      return UnitOccupied;
    case IconTypeEnum.UNIT_VACANT:
      return UnitVacant;
    case IconTypeEnum.HOME:
      return Home;
    case IconTypeEnum.PROPERTY_VALUE:
      return PropertyValue;
    case IconTypeEnum.RENTAL_INCOME_TOTAL:
      return RentalIncomeTotal;
    case IconTypeEnum.RENTAL_PROFILE:
      return RentalProfile;
    case IconTypeEnum.KEYS:
      return GiHouseKeys;
    case IconTypeEnum.UP:
      return AiFillCaretUp;
    case IconTypeEnum.STAT_TAGS:
      return VscTag;
    case IconTypeEnum.GRAPH:
      return BiStats;
    case IconTypeEnum.SETTLED:
      return FaRegHandshake;
    case IconTypeEnum.DISCOUNT:
      return CiDiscount1;
    case IconTypeEnum.JOURNAL:
      return IoMdJournal;
    case IconTypeEnum.DOWN:
      return AiFillCaretDown;
    case IconTypeEnum.PROPERTY:
      return MdOutlineMapsHomeWork;
    case IconTypeEnum.SETTING:
      return AiOutlineSetting;
    case IconTypeEnum.UNIT:
      return MdOutlineBed;
    case IconTypeEnum.CHECK:
      return AiOutlineCheck;
    case IconTypeEnum.Images:
      return BsImages;
    case IconTypeEnum.CLOSE:
      return IoIosClose;
    case IconTypeEnum.UN_CHECK:
      return AiOutlineCheckCircle;
    case IconTypeEnum.TOGGLE_OFF:
      return BsToggleOff;
    case IconTypeEnum.TOGGLE_ON:
      return BsToggleOn;
    case IconTypeEnum.LEFT:
      return BiChevronLeft;
    case IconTypeEnum.BUILDING:
      return BsBuilding;
    case IconTypeEnum.EDIT:
      return FiEdit3;
    case IconTypeEnum.ADDRESS:
      return HiOutlineLocationMarker;
    case IconTypeEnum.CANCEL:
      return MdOutlineCancelPresentation;
    case IconTypeEnum.SQUARE_CHECK:
      return BsSquare;
    case IconTypeEnum.SQUARE_FILL:
      return BsSquareFill;
    case IconTypeEnum.ARCHIVE:
      return MdOutlineDeleteSweep;
    case IconTypeEnum.CLOCK:
      return MdOutlineTimer;
      break;
    case IconTypeEnum.ADD:
      return IoIosAdd;
      break;
    case IconTypeEnum.RIGHT:
      return BiChevronRight;
      break;
    case IconTypeEnum.ADDRESS:
      return IoIosAdd;

      break;
    case IconTypeEnum.DELETE:
      return BiTrash;

      break;
    case IconTypeEnum.NOTIFICATION:
      return IoIosNotificationsOutline;
      break;

    case IconTypeEnum.CALENDER:
      return AiOutlineCalendar;
      break;

    case IconTypeEnum.SEARCH:
      return AiOutlineSearch;
      break;
    case IconTypeEnum.QUESTION:
      return BsQuestionCircle;
      break;

    default:
      return IoIosAdd;
  }
};

export default getIconHelper;
