import React from "react";
import { LeftRightHeaderInterface } from "./interfaces";

function LeftRightHeader({
  rightChildren,
  title,
  titleStyle,
  containerStyle,
}: LeftRightHeaderInterface) {
  return (
    <div className={`flex items-center justify-between ${containerStyle}`}>
      <div className={`font-bold text-[16px] whitespace-nowrap ${titleStyle}`}>
        {title}
      </div>
      <div>{rightChildren}</div>
    </div>
  );
}

export default LeftRightHeader;
